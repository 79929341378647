import React, { Component } from "react";
import Axios from "axios";

const ReminderTypes = {
    1: "Enkraten",
    2: "Tedenski",
    3: "Mesečni",
    4: "Letni",
    5: "2 letni",
    6: "5 letni"
};

const ReminderStatus = {
    0: "Zaprt",
    1: "Odprt",
    2: "V delu",
    3: "Potekel"
};

const TableRow = props => {
    return (
        <tr
            style={
                props.item.status === 3
                    ? { background: "rgba(255,0,0, 0.3)" }
                    : {}
            }
        >
            <td>{props.item.name}</td>
            <td>{ReminderTypes[props.item.reminder_type]}</td>
            <td>{props.item.description}</td>
            <td>{props.item.user.email}</td>
            <td>{ReminderStatus[props.item.status]}</td>
            <td>{window.FormateDateToSLO(props.item.on_time_reminder_date)}</td>
        </tr>
    );
};

class UserDetails extends Component {
    state = {};
    // Construtor
    constructor(props) {
        super(props);

        this.state = {
            user: null
        };
    }

    componentDidMount() {
        let idFromPathname = window.location.pathname.split("/")[2] || null;
        let user_id =
            (this.props.location.state && this.props.location.state.user_id) ||
            idFromPathname ||
            null;

        Axios.get(
            window.Settings.baseUrlApi + "/users/details/" + user_id,
            {},
            {}
        )
            .then(response => {
                this.setState({ user: response.data.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="display-4">Prijave uporabnika</h2>
                            <ul>
                                {this.state.user &&
                                    this.state.user.login_history &&
                                    this.state.user.login_history.map(
                                        (item, i) => {
                                            return (
                                                <li key={i}>
                                                    {new Intl.DateTimeFormat(
                                                        "sl-SI",
                                                        {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                            day: "2-digit"
                                                        }
                                                    ).format(
                                                        new Date(
                                                            item.created_at
                                                        )
                                                    )}
                                                </li>
                                            );
                                        }
                                    )}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="display-4">Prenešeni dokumenti</h2>
                            <ul>
                                {this.state.user &&
                                    this.state.user.document_download_history &&
                                    this.state.user.document_download_history.map(
                                        (item, i) => {
                                            return (
                                                <li key={item.id}>
                                                    {new Intl.DateTimeFormat(
                                                        "sl-SI",
                                                        {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                            day: "2-digit"
                                                        }
                                                    ).format(
                                                        new Date(
                                                            item.created_at
                                                        )
                                                    )} => {" "}
                                                    {item.document.name} (
                                                    {item.document.filename})
                                                </li>
                                            );
                                        }
                                    )}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="display-4">Opomniki</h2>
                            <table className="table col-md-12">
                                <thead>
                                    <tr>
                                        <th scope="col">Naziv</th>
                                        <th scope="col">Tip opomnika</th>
                                        <th scope="col">Opis</th>
                                        <th scope="col">Uporabnik</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Datum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.user &&
                                        this.state.user.reminders &&
                                        this.state.user.reminders.map(
                                            (item, i) => {
                                                return (
                                                    <TableRow
                                                        key={i}
                                                        item={item}
                                                    />
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default UserDetails;
