import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Header extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: ""
        };
    }

    search() {
        this.props.history.push({
            pathname: "/document-search",
            state: { searchTerm: this.state.searchTerm }
        });
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
                    <ul className="navbar-nav px-3">
                        <li className="nav-item text-nowrap">
                            <button title="Vrni se eno stran nazaj" onClick={() => {this.props.history.go(-1)}}>
                                <span className="oi oi-chevron-left" />
                            </button>
                        </li>
                    </ul>
                    <Link
                        className="navbar-brand col-sm-3 col-md-2 mr-0"
                        to="/"
                    >
                        <img style={{width: "10%"}} src="http://logo.si/logo.png" title="logo" alt="logo" />  Dokumenti
                    </Link>
                    <form
                        className="w-100"
                        onSubmit={event => {
                            event.preventDefault();
                            this.search();
                        }}
                    >
                        <input
                            className="form-control form-control-dark"
                            type="text"
                            placeholder="Išči po dokumentih"
                            aria-label="Search"
                            onChange={event => {
                                this.setState({
                                    searchTerm: event.target.value
                                });
                            }}
                        />
                    </form>
                    <ul className="navbar-nav px-3">
                        <li className="nav-item text-nowrap">
                            <Link className="nav-link" to="/logout">
                                Odjava
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default withRouter(Header);
