import React, { Component } from "react";
import "./App.css";
import Axios from "axios";

import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

/* import Axios from "axios"; */
import Header from "./Header";
import NavLeft from "./NavLeft";
import { Switch, Route, withRouter } from "react-router-dom";

import Main from "./Main";
import ProtectedRoute from "./ProtectedRoute";
/* Documents */
import Documents from "../documents";
import UploadDocument from "../documents/uploadDocument";
import EditDocument from "../documents/editDocument";
import UploadFolder from "../documents/UploadFolder";
/* User */
import Users from "../users";
import AddUser from "../users/addUser";
import EditUser from "../users/editUser";
import UserDetails from "../users/detail";
/* Reminder */
import Reminder from "../reminders";
import AddReminder from "../reminders/addReminder";
import EditReminder from "../reminders/editReminder";
import ReminderDetails from "../reminders/details";
/* DocumentCategories */
import DocumentCategories from "../documentCategories";
/* Login */
import Login from "./Login";
import Logout from "./Logout";
/* Login */
import Search from "../search";

toast.configure({
    autoClose: 1500,
    transition: Slide,
    draggable: false
    //same as ToastContainer props
});

class App extends Component {
    static Auth = {
        isLoggedIn: false,
        token: "",
        userId: "",
        username: "",
        role: ""
    };

    static Settings = {
        baseUrl: "http://logo.backend.lan",
        baseUrlApi: "http://logo.backend.lan/api"
    };

    constructor(props) {
        super(props);
        window.Settings = App.Settings;

        const cookies = new Cookies();
        let cookieAuth = cookies.getAll();

        if (cookieAuth.isLoggedIn) {
            window.Auth = cookieAuth;
            Axios.interceptors.request.use(
                config => {
                    let token = window.Auth.token;

                    if (token) {
                        config.headers["Authorization"] = `Bearer ${token}`;
                    }

                    return config;
                },

                error => {
                    return Promise.reject(error);
                }
            );
        } else {
            window.Auth = App.Auth;
        }

        // Catch all 401
        Axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response.status === 401) {
                    if(props.location) {
                        if(props.location.pathname === '/login') {
                            toast.error("Napačno uporabniško ime ali geslo",  {autoClose: 2500})
                            return error;
                        }
                    }
              
                    window.Auth = App.Auth;
                    cookies.remove("isLoggedIn", { path: "/" });
                    cookies.remove("token", { path: "/" });
                    cookies.remove("userId", { path: "/" });
                    cookies.remove("username", { path: "/" });
                    cookies.remove("role", { path: "/" });
                    window.location.reload();
                }
                else if(error.response.status === 404)
                {
                    toast.error(
                        "Prišlo je do napake: " + error.message + ". Osvežite stran, če se napaka ponavlja se obrnite na skrbnika.",
                        { autoClose: false}
                    );
                }
                else {
                    toast.error(
                        "Prišlo je do napake: " + error.message + ". Osvežite stran, če se napaka ponavlja se obrnite na skrbnika.",
                        { autoClose: false}
                    );
                }

                return error;
            }
        );
    }

    componentDidMount() {
        console.log("React app start");
    }

    render() {
        //var Header = window.Auth.isLoggedIn ? '<div className="row"><div className="navBar"><Header /></div></div>' : "";
        return (
            <div className="container-fluid">
                {window.Auth.isLoggedIn && <Header />}
                <div className="row">
                    {window.Auth.isLoggedIn && <NavLeft />}

                    <Switch>
                        <ProtectedRoute
                            exact
                            path="/"
                            component={Main}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        {/* Documents */}
                        <ProtectedRoute
                            path="/documents"
                            component={Documents}
                            loggedIn={window.Auth.isLoggedIn}
                            test="test"
                        />
                        <ProtectedRoute
                            path="/add-document"
                            component={UploadDocument}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        <ProtectedRoute
                            path="/edit-document/:id?"
                            component={EditDocument}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        <ProtectedRoute
                            path="/add-folder"
                            component={UploadFolder}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        {/* users */}
                        <ProtectedRoute
                            path="/users"
                            component={Users}
                            loggedIn={window.Auth.isLoggedIn}
                            test="test"
                        />
                        <ProtectedRoute
                            path="/add-user"
                            component={AddUser}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        <ProtectedRoute
                            path="/user-details/:id?"
                            component={UserDetails}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        <ProtectedRoute
                            path="/edit-user/:id?"
                            component={EditUser}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        {/* Categories */}
                        <ProtectedRoute
                            path="/document-categories"
                            component={DocumentCategories}
                            loggedIn={window.Auth.isLoggedIn}
                            test="test"
                        />
                        {/* Reminders */}
                        <ProtectedRoute
                            path="/reminders"
                            component={Reminder}
                            loggedIn={window.Auth.isLoggedIn}
                            test="test"
                        />
                        <ProtectedRoute
                            path="/add-reminder"
                            component={AddReminder}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        <ProtectedRoute
                            path="/reminder-details/:id?"
                            component={ReminderDetails}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        <ProtectedRoute
                            path="/edit-reminder/:id?"
                            component={EditReminder}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        
                        {/* Search */}
                        <ProtectedRoute
                            exact
                            path="/document-search"
                            component={Search}
                            loggedIn={window.Auth.isLoggedIn}
                        />
                        {/* Login */}
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={Logout} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(App);
