import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

class UploadDocument extends React.Component {
    static initialState = {
        file: null,
        name: "",
        document_category_id: "",
        description: "/",
        minimum_user_role: "",
        reset_after_add: false
    };

    constructor(props) {
        super(props);

        this.state = UploadDocument.initialState;

        this.state.categoriesJSON = null;
        this.state.users = null;

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);

        this.form = React.createRef();
    }

    onFormSubmit(e) {
        e.preventDefault(); // Stop form submit
        if (this.form.current.reportValidity()) {
            this.fileUpload(this.state.file);
        }
    }

    onChange(e) {
        let stateObject = {};
        stateObject[e.currentTarget.name] =
            e.currentTarget.name === "file"
                ? e.target.files[0]
                : e.target.value;
        this.setState(stateObject);
    }

    onChangeRedirect = e => {
        this.setState({ reset_after_add: e.target.checked });
    };

    fileUpload(file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", this.state.name);
        formData.append(
            "document_category_id",
            this.state.document_category_id
        );
        formData.append("description", this.state.description);
        formData.append("minimum_user_role", this.state.minimum_user_role);

        Axios.post(window.Settings.baseUrlApi + "/documents", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                if (this.state.reset_after_add) {
                    let users = this.state.users;
                    let categoriesJSON = this.state.categoriesJSON;
                    let reset_after_add = this.state.reset_after_add;

                    this.setState(UploadDocument.initialState);
                    this.setState({ categoriesJSON: categoriesJSON });
                    this.setState({ users: users });
                    this.setState({ reset_after_add: reset_after_add });
                    toast.success("Dokument shranjen");
                } else {
                    toast.success("Dokument shranjen");
                    this.props.history.push({
                        pathname: "documents"
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {
        // Get categories
        Axios.get(window.Settings.baseUrlApi + "/document-categories", {}, {})
            .then(response => {
                this.setState({ categoriesJSON: response.data });
            })
            .catch(error => {
                console.log(error);
            });
        // Get users
        Axios.get(window.Settings.baseUrlApi + "/users", {}, {})
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Dodaj nov dokument
                        </h1>
                        <form
                            className="col-md-4"
                            onSubmit={this.onFormSubmit}
                            ref={this.form}
                        >
                            {/*  Naziv dokumenta */}
                            <div className="form-group">
                                <label htmlFor="name">Naziv dokumenta</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    name="name"
                                    onChange={this.onChange}
                                    value={this.state.name}
                                    aria-describedby="email_help"
                                    placeholder="Naslov"
                                />
                                <small
                                    id="email_help"
                                    className="form-text text-muted"
                                >
                                    Opisen naziv dokumenta
                                </small>
                            </div>
                            {/*  Kategorija dokumenta */}
                            <div className="form-group">
                                <label htmlFor="document_category_id">
                                    Kategorija dokumenta
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="document_category_id"
                                    name="document_category_id"
                                    onChange={this.onChange}
                                    value={this.state.document_category_id}
                                    placeholder="Kategorija"
                                    aria-describedby="document_category_id_help"
                                >
                                    <option value="">Izberi kategorijo</option>
                                    {this.state &&
                                        this.state.categoriesJSON &&
                                        this.state.categoriesJSON.map(
                                            (object, i) => (
                                                <option
                                                    obj={object}
                                                    key={object.id}
                                                    value={object.id}
                                                >
                                                    {object.name}
                                                </option>
                                            )
                                        )}
                                </select>
                                <small
                                    id="document_category_id_help"
                                    className="form-text text-muted"
                                >
                                    V kateri kategoriji bo dokument prikazan pri
                                    pregledu
                                </small>
                            </div>
                            {/*  Opis dokumenta */}
                            <div className="form-group">
                                <label htmlFor="description">
                                    Opis dokumenta
                                </label>
                                <textarea
                                    className="form-control form-control-sm"
                                    rows="8"
                                    style={{ height: "100%" }}
                                    name="description"
                                    onChange={this.onChange}
                                    value={this.state.description}
                                    id="description"
                                />
                            </div>
                            {/*  Pravice dokumenta */}
                            <div className="form-group">
                                <label htmlFor="minimum_user_role">
                                    Pravice dokumenta
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="minimum_user_role"
                                    name="minimum_user_role"
                                    value={this.state.minimum_user_role}
                                    onChange={this.onChange}
                                    aria-describedby="minimum_user_role_help"
                                >
                                    <option value="">
                                        Izberi pravice dokumenta
                                    </option>
                                    <option value="50">Uporabnik</option>
                                    <option value="75">Administrator</option>
                                    <option value="90">
                                        Super administrator
                                    </option>
                                </select>
                                <small
                                    id="minimum_user_role_help"
                                    className="form-text text-muted"
                                >
                                    Kdo lahko vidi in prenese dokument
                                </small>
                            </div>
                            <div className="form-group">
                                <input
                                    required
                                    type="file"
                                    className="form-control-file"
                                    name="file"
                                    onChange={this.onChange}
                                    accept=".doc, .docx, .pdf, .txt"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Naloži dokument
                            </button>{" "}
                            in ostani na strani
                            <input
                                name="reset_after_add"
                                type="checkbox"
                                value="true"
                                onChange={this.onChangeRedirect}
                            />
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default UploadDocument;
