import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

class EditDocument extends Component {
    state = {};
    // Construtor
    constructor(props) {
        super(props);

        this.state = {
            document: null,

            form_name: "",
            form_document_category_id: "",
            form_description: "",
            form_minimum_user_role: ""
        };

        this.form = React.createRef();
    }

    OnFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        const formData = new FormData();

        formData.append("_method", "put");
        formData.append("name", this.state.form_name);
        formData.append(
            "document_category_id",
            this.state.form_document_category_id
        );
        formData.append("description", this.state.form_description);
        formData.append("minimum_user_role", this.state.form_minimum_user_role);

        Axios.post(
            window.Settings.baseUrlApi + "/documents/" + this.state.document.id,
            formData,
            {
                headers: {
                    "Content-Type": "x-www-form-urlencoded"
                }
            }
        )
            .then(response => {
                toast.success("Dokument shranjen");
                this.props.history.push({
                    pathname: "/documents"
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    onChange = e => {
        let stateObject = {};
        stateObject["form_" + e.currentTarget.name] =
            e.currentTarget.name === "file"
                ? e.target.files[0]
                : e.target.value;
        this.setState(stateObject);
    };

    componentDidMount() {
        let idFromPathname = window.location.pathname.split("/")[2] || null;
        let document_id =
            (this.props.location.state &&
                this.props.location.state.document_id) ||
            idFromPathname ||
            null;

        Axios.get(
            window.Settings.baseUrlApi + "/documents/" + document_id,
            {},
            {}
        )
            .then(response => {
                this.setState({ document: response.data });

                this.setState({ form_name: response.data.name });
                this.setState({
                    form_document_category_id:
                        response.data.document_category_id
                });
                this.setState({ form_description: response.data.description });
                this.setState({
                    form_minimum_user_role: response.data.minimum_user_role
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Uredi Dokument
                        </h1>
                        <form
                            className="col-md-4"
                            onSubmit={e => this.OnFormSubmit(e)}
                            ref={this.form}
                        >
                            {/*  Naziv dokumenta */}
                            <div className="form-group">
                                <label htmlFor="name">Naziv dokumenta</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    name="name"
                                    onChange={e => this.onChange(e)}
                                    value={this.state.form_name}
                                    aria-describedby="email_help"
                                    placeholder="Naslov"
                                />
                                <small
                                    id="email_help"
                                    className="form-text text-muted"
                                >
                                    Opisen naziv dokumenta
                                </small>
                            </div>

                            {/*  Opis dokumenta */}
                            <div className="form-group">
                                <label htmlFor="description">
                                    Opis dokumenta
                                </label>
                                <textarea
                                    className="form-control form-control-sm"
                                    rows="8"
                                    style={{ height: "100%" }}
                                    name="description"
                                    onChange={e => this.onChange(e)}
                                    value={this.state.form_description}
                                    id="description"
                                />
                            </div>

                            {/*  Kategorija dokumenta */}
                            <div className="form-group">
                                <label htmlFor="document_category_id">
                                    Kategorija dokumenta
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="document_category_id"
                                    name="document_category_id"
                                    onChange={e => this.onChange(e)}
                                    value={this.state.form_document_category_id}
                                    placeholder="Kategorija"
                                    aria-describedby="document_category_id_help"
                                />
                                <small
                                    id="document_category_id_help"
                                    className="form-text text-muted"
                                >
                                    V kateri kategoriji bo dokument prikazan pri
                                    pregledu
                                </small>
                            </div>

                            {/*  Pravice dokumenta */}
                            <div className="form-group">
                                <label htmlFor="minimum_user_role">
                                    Pravice dokumenta
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="minimum_user_role"
                                    name="minimum_user_role"
                                    onChange={e => this.onChange(e)}
                                    value={this.state.form_minimum_user_role}
                                    aria-describedby="minimum_user_role_help"
                                >
                                    <option value="">
                                        Izberi pravice dokumenta
                                    </option>
                                    <option value="50">Uporabnik</option>
                                    <option value="75">Administrator</option>
                                    <option value="90">
                                        Super administrator
                                    </option>
                                </select>

                                <small
                                    id="minimum_user_role_help"
                                    className="form-text text-muted"
                                >
                                    Kdo lahko vidi in prenese dokument
                                </small>
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Uredi dokument
                            </button>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default EditDocument;
