import React, { Component } from "react";
import Axios from "axios";
import Cookies from "universal-cookie";

class Logout extends Component {
    state = {};

    static Auth = {
        isLoggedIn: false,
        token: "",
        userId: "",
        username: "",
        role: ""
    };

    constructor(props) {
        super(props);
        
        Axios.get(window.Settings.baseUrlApi + "/users/logout", {}, {})
            .then(response => {
                const cookies = new Cookies();

                cookies.remove("isLoggedIn", { path: "/" });
                cookies.remove("token", { path: "/" });
                cookies.remove("userId", { path: "/" });
                cookies.remove("username", { path: "/" });
        
                window.Auth = Logout.Auth;

                this.props.history.push({
                    pathname: "/",
                    state: { message: "Uporabnik je bil odjavljen" }
                });
        
            })
            .catch(error => {
                console.log(error);
            });

       
    }

    render() {
        return null;
    }
}

export default Logout;
