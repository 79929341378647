import React, { Component, useCallback } from "react";
import Axios from "axios";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

class uploadFolderHelpers {
    static findValueInObject = (object, key) => {
        var value;

        Object.keys(object).some(function(k) {
            if (k.name === key) {
                value = object[k];
                return true;
            }

            if (object[k] && typeof object[k] === "object") {
                value = uploadFolderHelpers.findValueInObject(object[k], key);
                return value !== undefined;
            }
        });

        return value;
    };

    static getUniqueObjectsByKeys = (array, keyProps) => {
        const kvArray = array.map(entry => {
            const key = keyProps.map(k => entry[k]).join("|");
            return [key, entry];
        });

        const map = new Map(kvArray);
        return Array.from(map.values());
    };
}
const waitFor = (ms) => new Promise(r => setTimeout(r, ms));

class UploadFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriesJSON: null,
            document_category_id: null,
            //files_to_upload: null,
            minimum_user_role: null,
            is_uploading: false,
            count_all_files_to_upload: 0,
            count_current_uploaded_files: 0,
        };
        this.form = React.createRef();
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    checkForNewCategories = (callback = null) => {
        Axios.get(window.Settings.baseUrlApi + "/document-categories", {}, {})
            .then((response) => {
                this.setState({ categoriesJSON: response.data }, callback);
            })
            .catch(error => {
                console.log(error);
            });
    };

    flatten = (obj) => {
        var result = [];
        for(var key in obj) {
            result[key] = Object.create(null);
            for(var k in obj[key]) {
                result[key][k] = obj[key][k];
            }
        }
        return result;
    }

    onDrop = (accepted, rejected) => {
        if(!this.state.minimum_user_role) {
            toast.error("Izberite pravice dokumenta");
            return;
        }
        
        this.setState({count_all_files_to_upload: accepted.length})
        this.setState({count_current_uploaded_files: 0})
        var documentCategoryId = null,
            categoriesToCreate = [];

       

        // Naredi array za kategorije iz filepath datotek
        accepted.sort((a, b) => a.path.length - b.path.length);
        accepted.forEach(file => {
            let filePathSplitArray = file.path.split("/");

            if (filePathSplitArray.length == 3) {
                file['category_name'] = filePathSplitArray[filePathSplitArray.length - 2];
                file['category_parent'] = null;
                
            } else if (filePathSplitArray.length >= 4) {
                file['category_name'] = filePathSplitArray[filePathSplitArray.length - 2];
                file['category_parent'] = filePathSplitArray[filePathSplitArray.length - 3];
                
            }
        });
        // and make that shit unique
        let catToCreateFinal = uploadFolderHelpers.getUniqueObjectsByKeys(
            categoriesToCreate,
            ["name", "parent"]
        );
              
        //console.log(accepted);
        // Upload each file 
        const uploadFiles = async () => {
            await this.asyncForEach(accepted, async (file, key, catToCreateFinal ) => {
                this.setState({count_current_uploaded_files: this.state.count_current_uploaded_files + 1})
                await waitFor(1000);
                
                const formData = new FormData();
                formData.append("file", file);
                formData.append("category_name", file.category_name);
                formData.append("category_parent", file.category_parent);
                formData.append("minimum_user_role", this.state.minimum_user_role);

                if (this.state.document_category_id != null) {
                    formData.append("document_category_id", this.state.document_category_id);
                } 
    
                Axios.post(window.Settings.baseUrlApi + "/documents-bulk", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then(response => {
                       if(accepted.length == key + 1) {
                            toast.success("Vsi dokumenti shranjeni");
                            this.setState({ is_uploading: false });
                            this.setState({count_current_uploaded_files: null})
                            this.setState({count_all_files_to_upload: null})
                        }   
                           
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
            })
        };
        this.setState({ is_uploading: true });
        uploadFiles();
        

        return;
            
        
    };

    onChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] =
            e.currentTarget.name === "file"
                ? e.target.files[0]
                : e.target.value;
        this.setState(stateObject);
    };

    componentDidMount() {
        this.checkForNewCategories();
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Dodaj mapo
                        </h1>
                        <form
                            className="col-md-12"
                            /* onSubmit={this.onFormSubmit} */
                            ref={this.form}
                            style={{ textAlign: "center", paddingTop: "20px" }}
                        >
                            {/*  Kategorija dokumenta */}
                            <div
                                className="form-group col-md-3"
                                style={{
                                    display: "inline-block",
                                    verticalAlign: "top"
                                }}
                            >
                                <label htmlFor="document_category_id">
                                    Kategorija dokumenta
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="document_category_id"
                                    name="document_category_id"
                                    onChange={this.onChange}
                                    placeholder="Kategorija"
                                    aria-describedby="document_category_id_help"
                                >
                                    <option value="">Izberi kategorijo</option>
                                    {this.state &&
                                        this.state.categoriesJSON &&
                                        this.state.categoriesJSON.map(
                                            (object, i) => (
                                                <option
                                                    obj={object}
                                                    key={object.id}
                                                    value={object.id}
                                                >
                                                    {object.name}
                                                </option>
                                            )
                                        )}
                                </select>
                                <small
                                    id="document_category_id_help"
                                    className="form-text text-muted"
                                >
                                    V kateri kategoriji bo dokument prikazan pri
                                    pregledu. Če kategorija ni izbrana bo
                                    uporabljeno ime mape (če kategorija ne
                                    obstaja bo ustvarjena nova kategorija)
                                </small>
                            </div>
                            {/*  Pravice dokumenta */}
                            <div
                                className="form-group col-md-3"
                                style={{
                                    display: "inline-block",
                                    verticalAlign: "top"
                                }}
                            >
                                <label htmlFor="minimum_user_role">
                                    Pravice dokumenta
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="minimum_user_role"
                                    name="minimum_user_role"
                                    onChange={this.onChange}
                                    aria-describedby="minimum_user_role_help"
                                >
                                    <option value={null}>
                                        Izberi pravice dokumenta
                                    </option>
                                    <option value="50">Uporabnik</option>
                                    <option value="75">Administrator</option>
                                    <option value="90">
                                        Super administrator
                                    </option>
                                </select>
                                <small
                                    id="minimum_user_role_help"
                                    className="form-text text-muted"
                                >
                                    Kdo lahko vidi in prenese dokument. Velja za
                                    vse dokumente v mapi
                                </small>
                            </div>
                            <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <div
                                            {...getRootProps({
                                                className: "dropzone"
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{ fontSize: "30px" }}>
                                                Sem spustite mapo <br />
                                                <span
                                                    className="oi oi-cloud-upload"
                                                    style={{
                                                        fontSize: "110px"
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {this.state.is_uploading && <div style={{
                                position: "absolute",
                                width: "80%",
                                height: "400px",
                                backgroundColor: "#FFF",
                                top: "5%",
                                left: "10%",
                                fontSize: "32px"
                                }}
                            >
                               
                                <div>Datoteke se prenašajo. Ne zaprite ali zapustite strani. Če stran zaprete se bo prenos datotek ustavil na trenutni datoteki.</div>

                               
                                {this.state.count_all_files_to_upload > 0 &&
                                    <div> Prenaša se datoteka {this.state.count_current_uploaded_files} od {this.state.count_all_files_to_upload} </div>
                                }
                            </div>}
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default UploadFolder;
