import React, { Component } from "react";
import Axios from "axios";
import CategoryTreeView from "./categoryTreeView";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

const SortFields = props => {
    return (
        <React.Fragment>
            <button
                title="Naraščajoče"
                className="btn btn-outline-secondary btn-sm  ml-3"
                onClick={() => {
                    props.updateSort(props.order_by, "ASC");
                    props.getDocumentsForCurrentPathname();
                }}
            >
                <span className="oi oi-caret-top" />
            </button>
            <button
                title="Padajoče"
                className="btn btn-outline-secondary btn-sm ml-1"
                onClick={() => {
                    props.updateSort(props.order_by, "DESC");
                    props.getDocumentsForCurrentPathname();
                }}
            >
                <span className="oi oi-caret-bottom" />
            </button>
        </React.Fragment>
    );
};
const TableRow = props => {
    return (
        <tr>
            <td>
                <a
                    href={
                        window.Settings.baseUrlApi + "/document_download/" + window.Auth.userId +"/" +
                        props.item.id
                    }
                >
                    {props.item.name}
                </a>
            </td>
            <td>{props.item.filename}</td>
            <td>{props.item.user.email}</td>
            <td>{window.FormateDateToSLO(props.item.created_at)}</td>
            <td>
                <button
                    title="Uredi dokument"
                    className={(window.Auth.role >= 90 ? "" : " hidden ") + "btn btn-outline-info btn-sm float-right"}
                    onClick={() => {
                        props.editDocument(props.item.id);
                    }}
                >
                    <span className="oi oi-cog" />
                </button>
                <button
                    title="Izbriši dokument!"
                    className={(window.Auth.role >= 90 ? "" : " hidden ") + "btn btn-outline-danger btn-sm float-right mr-2"}
                    onClick={() => {
                        props.deleteDocument(props.item.id);
                    }}
                >
                    <span className="oi oi-trash" />
                </button>
            </td>
        </tr>
    );
};
class ShowAllDocuments extends Component {
    // Class vars
    state = {};

    updateSort = (order_by, dir) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?order_by=" + order_by + "&dir=" + dir
        });
    };

    deleteDocument = id => {
        if (window.confirm("Ste prepričani da želite izbrisati dokument?")) {
            Axios.delete(window.Settings.baseUrlApi + "/documents/" + id, {}, {})
                .then(response => {
                   

                    this.getDocumentsForCurrentPathname();
                    toast.success("Dokument je izbrisan");
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };
    editDocument = id => {
        this.props.history.push({
            pathname: "/edit-document/" + id,
            state: { document_id: id }
        });
    };
    // Updati state za dokumente glede na izbrano kategorijo
    updateStateDocuments = (e, id) => {
        let url = window.Settings.baseUrlApi + "/documents";

        if (id > 0) {
            url += "/document_category/" + id;
        }

        Axios.get(url, {}, {})
            .then(response => {
                this.setState({ documents: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
    getDocumentsForCurrentPathname = () => {
        Axios.get(
            window.Settings.baseUrlApi + 
                this.props.history.location.pathname +
                this.props.history.location.search,
            {},
            {}
        )
            .then(response => {
                this.setState({ documents: response.data });
                this.setState({ isLoading: false });
                
            })
            .catch(error => {
                console.log(error);
            });
    };

    // Construtor
    constructor(props) {
        super(props);

        this.state = {
            documents: null,
            isLoading: true
        };
    }

    componentDidMount() {
        // parse querystring
        //const queryString = require("query-string");
        //var parsed = queryString.parse(this.props.history.location.pathname);
        this.getDocumentsForCurrentPathname();
    }

    render() {
        if (this.state.isLoading) {
            return(<Loader type="ThreeDots" color="#ccc" height={80} width={80} />);
        } else {
            return (
                <React.Fragment>
                    <CategoryTreeView action={this.updateStateDocuments} />
                    <table className="table col-md-10">
                        <thead>
                            <tr>
                                <th scope="col">
                                    Naslov{" "}
                                    <SortFields
                                        order_by="name"
                                        updateSort={this.updateSort}
                                        getDocumentsForCurrentPathname={
                                            this.getDocumentsForCurrentPathname
                                        }
                                    />
                                </th>
                                <th scope="col">
                                    Datoteka
                                    <SortFields
                                        order_by="filename"
                                        updateSort={this.updateSort}
                                        getDocumentsForCurrentPathname={
                                            this.getDocumentsForCurrentPathname
                                        }
                                    />
                                </th>
                                <th scope="col">
                                    Lastnik
                                    <SortFields
                                        order_by="user_id"
                                        updateSort={this.updateSort}
                                        getDocumentsForCurrentPathname={
                                            this.getDocumentsForCurrentPathname
                                        }
                                    />
                                </th>
                                <th scope="col">
                                    Datum
                                    <SortFields
                                        order_by="created_at"
                                        updateSort={this.updateSort}
                                        getDocumentsForCurrentPathname={
                                            this.getDocumentsForCurrentPathname
                                        }
                                    />
                                </th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state &&
                                this.state.documents &&
                                this.state.documents.map((item, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            item={item}
                                            deleteDocument={this.deleteDocument}
                                            editDocument={this.editDocument}
                                        />
                                    );
                                })}
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    }
}

export default ShowAllDocuments;
