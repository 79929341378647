import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

class AddReminder extends Component {
    state = {};

    static initialState = {
        name: "",
        notify_user_days_before: "",
        reminder_type: 1,
        description: "",
        status: 1,
        week: "",
        month: "",
        day: "",
        on_time_reminder_date: ""
    };

    constructor(props) {
        super(props);

        this.state = AddReminder.initialState;

        this.state.users = null;
        this.state.documents = null;
        this.state.assigned_users = [];
        this.state.assigned_documents = [];
        this.state.assigned_documents_names = {};

        this.form = React.createRef();
    }

    onFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            if (this.state.assigned_users.length === 0) {
                toast.error("Prosim določite vsaj enega uporabnika");
                return;
            }
            const formData = new FormData();
            formData.append("name", this.state.name);

            formData.append("name", this.state.name);
            formData.append(
                "notify_user_days_before",
                this.state.notify_user_days_before
            );
            formData.append("reminder_type", this.state.reminder_type);
            formData.append("description", this.state.description);
            formData.append("status", this.state.status);
            formData.append("assigned_users", this.state.assigned_users);
            formData.append(
                "assigned_documents",
                this.state.assigned_documents
            );
            formData.append(
                "on_time_reminder_date",
                this.state.on_time_reminder_date
            );

            Axios.post(window.Settings.baseUrlApi + "/reminders", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.status == "error") {
                            //
                        } else if (response.data.status == "success") {
                            if (this.state.reset_after_add) {
                                toast.success("Opomnik shranjen");
                                this.setState(AddReminder.initialState);

                                this.state.documents = null;
                                this.state.assigned_users = [];
                                this.state.assigned_documents = [];
                                this.state.assigned_documents_names = {};

                                this.props.history.push({ pathname: "/" });
                                this.props.history.push({
                                    pathname: "/add-reminder"
                                });
                            } else {
                                toast.success("Opomnik shranjen");
                                this.props.history.push({
                                    pathname: "reminders"
                                });
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };
    // On change handles
    onChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };

    onChangeUserCheckbox = e => {
        let assigned_users = this.state.assigned_users;

        if (!assigned_users.includes(e.target.value)) {
            assigned_users.push(e.target.value);
            this.setState({ assigned_users });
        } else {
            assigned_users.splice(assigned_users.indexOf(e.target.value), 1);
            this.setState({ assigned_users });
        }
    };

    onChangeDocumentheckbox = e => {
        let assigned_documents = this.state.assigned_documents;
        let assigned_documents_names = this.state.assigned_documents_names;

        if (!assigned_documents.includes(e.target.value)) {
            assigned_documents.push(e.target.value);
            this.setState({ assigned_documents });
        } else {
            assigned_documents.splice(
                assigned_documents.indexOf(e.target.value),
                1
            );
            this.setState({ assigned_documents });
        }

        if (e.target.value in this.state.assigned_documents_names) {
            delete assigned_documents_names[e.target.value];
            this.setState({ assigned_documents_names });
        } else {
            assigned_documents_names[e.target.value] =
                e.target.dataset.documentName;
            this.setState({ assigned_documents_names });
        }
    };

    onChangeDocumentInput = e => {
        //let searchTerm = this.props.location.state.searchTerm;
        if (e.target.value.length > 0) {
            const formData = new FormData();
            formData.append("searchTerm", e.target.value);

            Axios.post(
                window.Settings.baseUrlApi + "/documents/search",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.status == "success") {
                            this.setState({
                                documents: response.data.resultDocumentsOnly
                            });
                        }
                    } else {
                        toast.error(
                            "Prišlo je do neznane napake. Če se ponovi se prosim obrnite na skrbnika"
                        );
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            this.setState({
                documents: null
            });
        }
    };

    onChangeRedirect = e => {
        this.setState({ reset_after_add: e.target.checked });
    };

    componentDidMount() {
        // Get users
        Axios.get(window.Settings.baseUrlApi + "/users", {}, {})
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    render() {
        const DaysInMonth = () => {
            let obj = [];
            for (let i = 1; i < 32; i++) {
                obj[i] = i;
            }

            return obj;
        };

        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Dodaj nov opomnik
                        </h1>
                        <form
                            className="col-md-4"
                            onSubmit={this.onFormSubmit}
                            ref={this.form}
                        >
                            {/*  Naziv opomnika */}
                            <div className="form-group">
                                <label htmlFor="name">Naziv</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    placeholder="Ime"
                                />
                            </div>
                            {/*  Dni pred opomnikom */}
                            <div className="form-group">
                                <label htmlFor="notify_user_days_before">
                                    Dni pred opomnikom
                                </label>
                                <input
                                    required
                                    type="number"
                                    min="1"
                                    max="365"
                                    className="form-control form-control-sm"
                                    id="notify_user_days_before"
                                    name="notify_user_days_before"
                                    value={this.state.notify_user_days_before}
                                    onChange={this.onChange}
                                    placeholder="število dni "
                                    aria-describedby="notify_user_days_before_help"
                                />
                                <small
                                    id="notify_user_days_before_help"
                                    className="form-text text-muted"
                                >
                                    Obvesti uporabnika preko email X dni pred
                                    datum opomnika
                                </small>
                            </div>
                            {/*  Tip opomnika */}
                            <div className="form-group">
                                <label htmlFor="reminder_type">
                                    Tip opomnika
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="reminder_type"
                                    name="reminder_type"
                                    value={this.state.reminder_type}
                                    onChange={this.onChange}
                                >
                                    <option value="1">Enkraten</option>
                                    <option value="2">Tedenski</option>
                                    <option value="3">Mesečni</option>
                                    <option value="4">Letni</option>
                                    <option value="5">2 letni</option>
                                    <option value="7">3 letni</option>
                                    <option value="6">5 letni</option>
                                </select>
                            </div>
                            {/*  Opis opomnika */}
                            <div className="form-group">
                                <label htmlFor="description">Opis</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChange}
                                    placeholder="Opis"
                                />
                            </div>
                            {/*  Status opomnika */}
                            <div className="form-group">
                                <label htmlFor="status">Status</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="status"
                                    name="status"
                                    value={this.state.status}
                                    onChange={this.onChange}
                                >
                                    <option value="1">Odprt</option>
                                    <option value="2">V delu</option>
                                    <option value="0">Končan</option>
                                </select>
                            </div>
                            {/*  Dan v tednu 
                            <div className="form-group">
                                <label htmlFor="day">Dan v tednu</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="day"
                                    name="day"
                                    value={this.state.day}
                                    onChange={this.onChange}
                                >
                                    <option value="null">
                                        Izberi dan v tednu
                                    </option>
                                    <option value="1">Ponedeljek</option>
                                    <option value="2">Torek</option>
                                    <option value="3">Sreda</option>
                                    <option value="4">Četrtek</option>
                                    <option value="5">Petek</option>
                                    <option value="6">Sobota</option>
                                    <option value="7">Nedelja</option>
                                </select>
                            </div>
                            */}
                            {/*  Dan v mesecu 
                            <div className="form-group">
                                <label htmlFor="week">Dan v mesecu</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="week"
                                    name="week"
                                    value={this.state.week}
                                    onChange={this.onChange}
                                >
                                    <option value="0">Izberi dan v mesecu</option>
                                    {DaysInMonth().map((object, i) => (
                                        <option obj={object} key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            */}
                            {/*  Mesec
                            <div className="form-group">
                                <label htmlFor="month">Mesec</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="month"
                                    name="month"
                                    value={this.state.month}
                                    onChange={this.onChange}
                                >
                                    <option value="null">Izberi mesec</option>
                                    <option value="1">Januar</option>
                                    <option value="2">Februar</option>
                                    <option value="3">Marec</option>
                                    <option value="4">April</option>
                                    <option value="5">Maj</option>
                                    <option value="6">Junij</option>
                                    <option value="7">Julij</option>
                                    <option value="8">Avgust</option>
                                    <option value="9">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            */}
                            {/*  Datum za enkratni opomnik */}
                            <div className="form-group">
                                <label htmlFor="on_time_reminder_date">
                                    Datum naslednjega opomnika
                                </label>
                                <input
                                    required
                                    type="date"
                                    className="form-control form-control-sm"
                                    id="on_time_reminder_date"
                                    name="on_time_reminder_date"
                                    value={this.state.on_time_reminder_date}
                                    onChange={this.onChange}
                                    aria-describedby="on_time_reminder_data"
                                    placeholder="Datum"
                                />
                                <small
                                    id="on_time_reminder_data"
                                    className="form-text text-muted"
                                >
                                    Če želite tedenski opomnik vsak ponedeljek
                                    izberite datum naslednjega ponedeljka. Za
                                    mesečni opomnik vsakega 15. v mesecu
                                    izberete prvi 15. od datuma dodajanja
                                    opomnika. Enako za letni opomnik.
                                </small>
                            </div>
                            Dodeli uporabnike: <br />
                            {this.state &&
                                this.state.users &&
                                this.state.users.map((object, i) => (
                                    <div key={object.id} className="form-check">
                                        <input
                                            className="form-check-input"
                                            onChange={this.onChangeUserCheckbox}
                                            type="checkbox"
                                            value={object.id}
                                            id={"check-user-" + object.id}
                                            name="assigned_users"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={"check-user-" + object.id}
                                        >
                                            {object.name} ({object.email})
                                        </label>
                                    </div>
                                ))}
                            <br />
                            {/*  Dodeli dokumente */}
                            <div className="form-group">
                                <label htmlFor="assigned_documents">
                                    Dodeli dokumente
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="assigned_documents"
                                    name="assigned_documents"
                                    onChange={this.onChangeDocumentInput}
                                    placeholder=""
                                    autoComplete="off"
                                />
                            </div>
                            <select
                                size="7"
                                style={{
                                    minWidth: "425 px"
                                }}
                            >
                                {this.state &&
                                    this.state.documents &&
                                    this.state.documents.map((object, i) => (
                                        <option
                                            key={"documents-" + object.id}
                                            className="form-control form-control-sm"
                                            onClick={
                                                this.onChangeDocumentheckbox
                                            }
                                            type="checkbox"
                                            value={object.id}
                                            id={"check-document-" + object.id}
                                            name="assigned_users"
                                            data-document-name={object.name}
                                        >
                                            {object.name}
                                        </option>
                                    ))}
                            </select>
                            <br />
                            Dodeljeni dokumenti:
                            {this.state &&
                                this.state.assigned_documents &&
                                this.state.assigned_documents.map(
                                    (object, i) => (
                                        <div
                                            key={"assigned-" + object}
                                            className="form-check"
                                        >
                                            <input
                                                className="form-check-input"
                                                onChange={
                                                    this.onChangeDocumentheckbox
                                                }
                                                type="checkbox"
                                                checked="checked"
                                                value={object}
                                                id={"check-document-" + object}
                                                name="assigned_users"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={
                                                    "check-document-" + object
                                                }
                                            >
                                                {
                                                    this.state
                                                        .assigned_documents_names[
                                                        object
                                                    ]
                                                }
                                            </label>
                                        </div>
                                    )
                                )}
                            <br />
                            <button type="submit" className="btn btn-primary">
                                Dodaj opomnik
                            </button>
                            in ostani na strani
                            <input
                                name="reset_after_add"
                                type="checkbox"
                                value="true"
                                onChange={this.onChangeRedirect}
                            />
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default AddReminder;
