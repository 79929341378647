import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

const ReminderDocumentsContainer = props => {
    return (
        props.reminder.documents.length > 0 && (
            <div className="row">
                <div className="col-md-12">
                    <h4 className="display-4 display-4-sm-font">
                        Pripeti dokumenti
                    </h4>
                    <ul>
                        {props.reminder &&
                            props.reminder.documents.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <a
                                            href={
                                                window.Settings.baseUrlApi +
                                                "/document_download/" +
                                                window.Auth.userId +
                                                "/" +
                                                item.id
                                            }
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        )
    );
};

const ReminderTypes = {
    1: "Enkraten",
    2: "Tedenski",
    3: "Mesečni",
    4: "Letni",
    5: "2 letni",
    6: "5 letni",
    7: "3 letni"
};

const Status = {
    0: "Zaprt",
    1: "Odprt",
    2: "V delu",
    3: "Potekel"
};

const StatusBadge = {
    0: "badge badge-primary",
    1: "badge badge-success",
    2: "badge badge-warning",
    3: "badge badge-danger"
};

const TableRow = props => {
    return (
        <tr>
            <td>{props.item.name}</td>
            <td>{ReminderTypes[props.item.reminder_type]}</td>
            <td>{props.item.user.email}</td>
            <td>{props.item.notify_user_days_before}</td>
            <td>{window.FormateDateToSLO(props.item.on_time_reminder_date)}</td>
        </tr>
    );
};
class ReminderDetails extends Component {
    state = {};

    // Construtor
    constructor(props) {
        super(props);
        console.log("reminder_id");

        this.state = {
            reminder: null,
            finished_note: " ",
            users: null,
            add_user_ids: null
        };

        this.form = React.createRef();
        this.formAddUser = React.createRef();
    }

    onTextareaChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };
    // On change handles
    onChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };

    onFormSubmitAddUser = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            let assigned_documents = [];
            for (let index = 0; index < this.state.reminder.documents.length; index++) {
                assigned_documents.push(this.state.reminder.documents[index].id);
            }
            console.log(assigned_documents);
            if (this.state.add_user_ids.length === 0) {
                toast.error("Prosim določite vsaj enega uporabnika");
                return;
            }
            const formData = new FormData();
            formData.append("name", this.state.reminder.name);

            formData.append("name", this.state.reminder.name);
            formData.append(
                "notify_user_days_before",
                this.state.reminder.notify_user_days_before
            );
            formData.append("reminder_type", this.state.reminder.reminder_type);
            formData.append("description", this.state.reminder.description);
            formData.append("status", this.state.reminder.status);
            formData.append("assigned_users", this.state.add_user_ids);
            formData.append("assigned_documents", assigned_documents);
            formData.append(
                "on_time_reminder_date",
                this.state.reminder.on_time_reminder_date
            );

            Axios.post(window.Settings.baseUrlApi + "/reminders", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(response => {
                    toast.success("Opomnik uspešno dodeljen uporabniku");
                    // dodeli uporabnika
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    onFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            const formData = new FormData();
            formData.append("_method", "put");

            formData.append("finished_note", this.state.finished_note);

            Axios.post(
                window.Settings.baseUrlApi +
                    "/reminders/finish/" +
                    +this.state.reminder.id,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then(response => {
                    toast.success("Opomnik zaključen");
                    this.props.history.push({
                        pathname: "/reminders"
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };
    componentDidMount() {
        let idFromPathname = window.location.pathname.split("/")[2] || null;
        let reminder_id =
            (this.props.location.state &&
                this.props.location.state.reminder_id) ||
            idFromPathname ||
            null;

        Axios.get(
            window.Settings.baseUrlApi + "/reminders/details/" + reminder_id,
            {},
            {}
        )
            .then(response => {
                this.setState({ reminder: response.data.data });
                this.setState({
                    finished_note: response.data.data.finished_note
                });
            })
            .catch(error => {
                console.log(error);
            });

        // Get users
        Axios.get(window.Settings.baseUrlApi + "/users", {}, {})
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="display-4">
                                {this.state.reminder &&
                                    this.state.reminder.name}
                            </h2>
                            <br />
                            <table className="table col-md-12">
                                <thead>
                                    <tr>
                                        <th scope="col">Naziv</th>
                                        <th scope="col">Tip opomnika</th>
                                        <th scope="col">Uporabnik</th>
                                        <th scope="col">Opomni dni pred</th>
                                        <th scope="col">Datum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state && this.state.reminder && (
                                        <TableRow item={this.state.reminder} />
                                    )}
                                </tbody>
                            </table>
                            <h4 className="display-4 display-4-sm-font">
                                Opis
                            </h4>
                            <p>
                                {this.state.reminder &&
                                    this.state.reminder.description}
                            </p>
                        </div>
                    </div>
                    {this.state.reminder &&
                        this.state.reminder.documents.length > 0 && (
                            <ReminderDocumentsContainer
                                reminder={this.state.reminder}
                            />
                        )}
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="display-4 display-4-sm-font">
                                Status
                            </h4>
                            <p>
                                <span
                                    style={{ fontSize: "18px" }}
                                    className={
                                        this.state.reminder &&
                                        StatusBadge[this.state.reminder.status]
                                    }
                                >
                                    {this.state.reminder &&
                                        Status[this.state.reminder.status]}{" "}
                                </span>
                            </p>

                            {window.Auth.role >= 75 && (
                                <div>
                                    <h4 className="display-4 display-4-sm-font">
                                        Dodeli opomnik uporabniku
                                    </h4>
                                    <form
                                        style={{ width: "50%" }}
                                        onSubmit={this.onFormSubmitAddUser}
                                        ref={this.formAddUser}
                                    >
                                        <br />

                                        <select
                                            required
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="add_user_ids"
                                            name="add_user_ids"
                                            onChange={this.onChange}
                                            placeholder="Uporabniki"
                                            defaultValue=""
                                        >
                                            <option value="">
                                                Izberi uporabnika
                                            </option>
                                            {this.state &&
                                                this.state.users &&
                                                this.state.users.map(
                                                    (object, i) => (
                                                        <option
                                                            obj={object}
                                                            key={object.id}
                                                            value={object.id}
                                                        >
                                                            {object.name}
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                        <br />
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Dodeli uporabnika
                                        </button>
                                    </form>
                                </div>
                            )}
                            {/*  */}
                            <br />
                            <br />
                            <form
                                style={{ width: "50%" }}
                                onSubmit={this.onFormSubmit}
                                ref={this.form}
                            >
                                <div className="form-group">
                                    <label htmlFor="finished_note">
                                        Opombe
                                    </label>
                                    <textarea
                                        disabled={
                                            this.state.reminder &&
                                            this.state.reminder.status != 0
                                                ? ""
                                                : "disabled"
                                        }
                                        className="form-control form-control-sm"
                                        rows="10"
                                        cols="75"
                                        name="finished_note"
                                        onChange={this.onTextareaChange}
                                        id="finished_note"
                                        value={
                                            this.state.finished_note
                                                ? this.state.finished_note
                                                : ""
                                        }
                                    />
                                </div>
                                <br />
                                {this.state.reminder &&
                                    this.state.reminder.status != 0 && (
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Zaključi opomnik
                                        </button>
                                    )}
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ReminderDetails;
