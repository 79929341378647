import React, { Component } from "react";
/*
import Home from "../home";
import Documents from "../documents";
import ProtectedRoute from "./ProtectedRoute";
import Axios from "axios";
*/
class Main extends Component {
    state = {};
    
    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Prva stran</h1>
                    {this.props.location.state && this.props.location.state.error &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.location.state.error}
                        </div>
                    }
                </div>
            </main>
        );
    }
}

export default Main;
