import React, {/*  Component */ } from "react";
import { /* BrowserRouter, */ Route, Redirect/*, Link */ } from "react-router-dom";
import Login from "./Login";

const ProtectedRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={props => {
                return loggedIn ? (
                    <Comp {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                prevLocation: path,
                                error: "Za dostop se je potrebno prijaviti"
                            }
                        }}
                    />
                );
            }}
        />
    );
};
export default ProtectedRoute;
