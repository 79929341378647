import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import UploadDocument from "./uploadDocument";
import ShowAllDocuments from "./showAllDocuments";
class Documents extends Component {
    state = {};
    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Pregled
                        </h1>
                        <Route
                            path="/documents/:model?/:id?"
                            component={ShowAllDocuments}
                        />
                    </div>
                </div>
            </main>
        );
    }
}

export default Documents;
