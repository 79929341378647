import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

const BuildTree = flatArray => {
    var tree = [],
        mappedArray = {},
        arrayElement,
        mappedElement;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = flatArray.length; i < len; i++) {
        arrayElement = flatArray[i];
        mappedArray[arrayElement.id] = arrayElement;
        mappedArray[arrayElement.id]["children"] = [];
    }

    for (var id in mappedArray) {
        if (mappedArray.hasOwnProperty(id)) {
            mappedElement = mappedArray[id];
            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElement.parent_id) {
                mappedArray[mappedElement["parent_id"]]["children"].push(
                    mappedElement
                );
            }
            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElement);
            }
        }
    }

    return tree;
};
const renderMenu = (items, action) => {
    if (!items) return <div />;

    return (
        <ul className="col-md-12">
            {items.map(i => {
                return (
                    <li key={i.id}>
                        {i.name}
                        <a  href="#"
                            style={{marginLeft:"5px"}}
                            title="Izbriši kategorijo!"
                            className=""
                            onClick={() => {
                                action(i.id);
                            }}
                        >
                            x
                        </a>
                        {i.children && renderMenu(i.children, action)}
                    </li>
                );
            })}
        </ul>
    );
};

const RenderUl = ({ data, action }) => {
    if (!data) return <div />;

    return (
        <ul className="col-md-6" style={{ paddingLeft: "20px" }}>
            {data.map(i => {
                return (
                    <li key={i.id}>
                        {i.name}
                        <a  href="#"
                            style={{marginLeft:"5px"}}
                            title="Izbriši kategorijo!"
                            className=""
                            onClick={() => {
                                action(i.id);
                            }}
                        >
                            x
                        </a>

                        {i.children && renderMenu(i.children, action)}
                    </li>
                );
            })}
        </ul>
    );
};
class DocumentCategories extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            parent_id: "",
            categoriesJSON: null,
            categoriesTreeView: null
        };

        this.form = React.createRef();
    }
    checkForNewData = () => {
        Axios.get(window.Settings.baseUrlApi + "/document-categories", {}, {})
            .then(response => {
                let tree = BuildTree(response.data);

                this.setState({ categoriesJSON: response.data });
                this.setState({ categoriesTreeView: tree });
            })
            .catch(error => {
                console.log(error);
            });
    };

    deleteCategory = id => {
        if (window.confirm("Ste prepričani da želite izbrisati kategorijo?")) {
            Axios.delete(
                window.Settings.baseUrlApi + "/document-categories/" + id,
                {},
                {}
            )
                .then(response => {
                    toast.success("Kategorija izbrisana");
                    this.checkForNewData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };
    onFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            const formData = new FormData();
            formData.append("name", this.state.name);

            formData.append("parent_id", this.state.parent_id);
            formData.append("description", this.state.name);
            formData.append("slug", this.state.name);

            Axios.post(
                window.Settings.baseUrlApi + "/document-categories",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.status == "error") {
                            //
                        } else if (response.data.status == "success") {
                            toast.success("Kategorija shranjena");
                            this.checkForNewData();
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };
    // On change handles
    onChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };
    componentDidMount() {
        this.checkForNewData();
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="display-4">Pregled kategorij</h2>

                            {this.state.categoriesTreeView && (
                                <RenderUl
                                    data={this.state.categoriesTreeView}
                                    action={this.deleteCategory}
                                />
                            )}
                            <form
                                className="col-md-4"
                                onSubmit={this.onFormSubmit}
                                ref={this.form}
                            >
                                {/*  Naziv dokumenta */}
                                <div className="form-group">
                                    <label htmlFor="name">
                                        Naziv Kategorije
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="name"
                                        name="name"
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        aria-describedby="email_help"
                                        placeholder="Naziv"
                                    />
                                </div>
                                {/*  Kategorija dokumenta */}
                                <div className="form-group">
                                    <label htmlFor="document_category_id">
                                        Nadkategorija
                                    </label>
                                    <select
                                        required
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="parent_id"
                                        name="parent_id"
                                        onChange={this.onChange}
                                        value={this.state.document_category_id}
                                        placeholder="Kategorija"
                                        aria-describedby="document_category_id_help"
                                    >
                                        <option value="0">Brez nadkategorije</option>
                                        {this.state &&
                                            this.state.categoriesJSON &&
                                            this.state.categoriesJSON.map(
                                                (object, i) => (
                                                    <option
                                                        obj={object}
                                                        key={object.id}
                                                        value={object.id}
                                                    >
                                                        {object.name}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Dodaj kategorijo
                                </button>{" "}
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default DocumentCategories;
