import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import App from "./includes/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "react-error-boundary";
import Axios from "axios";

window.FormateDateToSLO = date => {
    return new Intl.DateTimeFormat("sl-SI", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(new Date(date));
};

const MyFallbackComponent = ({ componentStack, error }) => (
    <div style={{ margin: "100px auto", width: "300px" }}>
        <p style={{color: "red", fontSize: "20px"}}>
            <strong>Prišlo je do kritične napake :( </strong>
        </p>
        
        <p>
            <strong>Napaka:</strong> {error.toString()}
        </p>
        <p>
            <strong>Osvežite stran, če se napaka ponovi prosim obvestite administratorja</strong> 
        </p>
    </div>
);

ReactDOM.render(
    <BrowserRouter>
        <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <App />
        </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
