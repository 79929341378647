import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

class EditReminder extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            reminder: null,

            form_name: "",
            form_notify_user_days_before: "",
            form_reminder_type: "",
            form_description: "",
            form_status: "",
            form_week: "",
            form_month: "",
            form_day: "",
            form_on_time_reminder_date: ""
        };

        this.form = React.createRef();
    }

    onFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            const formData = new FormData();
            formData.append("_method", "put");

            formData.append("name", this.state.form_name);
            formData.append(
                "notify_user_days_before",
                this.state.form_notify_user_days_before
            );
            formData.append("reminder_type", this.state.form_reminder_type);
            formData.append("description", this.state.form_description);
            formData.append("status", this.state.form_status);
            formData.append(
                "on_time_reminder_date",
                this.state.form_on_time_reminder_date
            );

            Axios.post(window.Settings.baseUrlApi + "/reminders/" +  + this.state.reminder.id, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(response => {
                    toast.success("Opomnik shranjen");
                    this.props.history.push({
                        pathname: "/reminders",
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    onChange = e => {
        let stateObject = {};
        stateObject["form_" + e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };

    componentDidMount() {
        let idFromPathname = window.location.pathname.split("/")[2] || null;
        let reminder_id =
            (this.props.location.state &&
                this.props.location.state.reminder_id) ||
            idFromPathname ||
            null;

        Axios.get(
            window.Settings.baseUrlApi + "/reminders/" + reminder_id,
            {},
            {}
        )
            .then(response => {
                this.setState({ reminder: response.data });

                this.setState({ form_name: response.data.name });
                this.setState({
                    form_notify_user_days_before:
                        response.data.notify_user_days_before
                });
                this.setState({
                    form_reminder_type: response.data.reminder_type
                });
                this.setState({ form_description: response.data.description });
                this.setState({ form_status: response.data.status });
                this.setState({ form_week: response.data.week });
                this.setState({ form_month: response.data.month });
                this.setState({ form_day: response.data.day });
                this.setState({
                    form_on_time_reminder_date:
                        response.data.on_time_reminder_date
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const DaysInMonth = () => {
            let obj = [];
            for (let i = 1; i < 32; i++) {
                obj[i] = i;
            }

            return obj;
        };

      /*   var mydate = null;
        if (this.state.form_on_time_reminder_date) {
            mydate = new Date(this.state.form_on_time_reminder_date);
        }
 */
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Uredi opomnik
                        </h1>
                        <form
                            className="col-md-4"
                            onSubmit={this.onFormSubmit}
                            ref={this.form}
                        >
                            {/*  Naziv opomnika */}
                            <div className="form-group">
                                <label htmlFor="name">Naziv</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    name="name"
                                    value={this.state.form_name}
                                    onChange={this.onChange}
                                    placeholder="Ime"
                                />
                            </div>

                            {/*  Dni pred opomnikom */}
                            <div className="form-group">
                                <label htmlFor="notify_user_days_before">
                                    Dni pred opomnikom
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="notify_user_days_before"
                                    name="notify_user_days_before"
                                    value={
                                        this.state.form_notify_user_days_before
                                    }
                                    onChange={this.onChange}
                                    placeholder="število dni "
                                    aria-describedby="notify_user_days_before_help"
                                />
                                <small
                                    id="notify_user_days_before_help"
                                    className="form-text text-muted"
                                >
                                    Obvesti uporabnika preko email X dni pred
                                    datum opomnika
                                </small>
                            </div>

                            {/*  Tip opomnika */}
                            <div className="form-group">
                                <label htmlFor="reminder_type">
                                    Tip opomnika
                                </label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="reminder_type"
                                    name="reminder_type"
                                    value={this.state.form_reminder_type}
                                    onChange={this.onChange}
                                >
                                    <option value="1">Enkraten</option>
                                    <option value="2">Tedenski</option>
                                    <option value="3">Mesečni</option>
                                    <option value="4">Letni</option>
                                    <option value="5">2 letni</option>
                                    <option value="7">3 letni</option>
                                    <option value="6">5 letni</option>
                                </select>
                            </div>

                            {/*  Opis opomnika */}
                            <div className="form-group">
                                <label htmlFor="description">Opis</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="description"
                                    name="description"
                                    value={this.state.form_description}
                                    onChange={this.onChange}
                                    placeholder="Opis"
                                />
                            </div>

                            {/*  Status opomnika */}
                            <div className="form-group">
                                <label htmlFor="status">Status</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="status"
                                    name="status"
                                    value={this.state.form_status}
                                    onChange={this.onChange}
                                >
                                    <option value="null">Izberi status</option>
                                    <option value="1">Odprt</option>
                                    <option value="2">V delu</option>
                                    <option value="3">Potekel</option>
                                    <option value="0">Končan</option>
                                </select>
                            </div>
                            {/*  Dan v tednu 
                            <div className="form-group">
                                <label htmlFor="day">Dan v tednu</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="day"
                                    name="day"
                                    value={this.state.form_day}
                                    onChange={this.onChange}
                                >
                                    <option value="null">
                                        Izberi dan v tednu
                                    </option>
                                    <option value="1">Ponedeljek</option>
                                    <option value="2">Torek</option>
                                    <option value="3">Sreda</option>
                                    <option value="4">Četrtek</option>
                                    <option value="5">Petek</option>
                                    <option value="6">Sobota</option>
                                    <option value="7">Nedelja</option>
                                </select>
                            </div>
                            */}

                            {/*  Dan v mesecu 
                            <div className="form-group">
                                <label htmlFor="week">Dan v mesecu</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="week"
                                    name="week"
                                    value={this.state.form_week}
                                    onChange={this.onChange}
                                >
                                    <option value="0">
                                        Izberi dan v mesecu
                                    </option>
                                    {DaysInMonth().map((object, i) => (
                                        <option obj={object} key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            */}

                            {/*  Mesec 
                            <div className="form-group">
                                <label htmlFor="month">Mesec</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="month"
                                    name="month"
                                    value={this.state.form_month}
                                    onChange={this.onChange}
                                >
                                    <option value="null">Izberi mesec</option>
                                    <option value="1">Januar</option>
                                    <option value="2">Februar</option>
                                    <option value="3">Marec</option>
                                    <option value="4">April</option>
                                    <option value="5">Maj</option>
                                    <option value="6">Junij</option>
                                    <option value="7">Julij</option>
                                    <option value="8">Avgust</option>
                                    <option value="9">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            */}
                            {/*  Datum za enkratni opomnik */}
                            <div className="form-group">
                                <label htmlFor="on_time_reminder_date">
                                    Datum naslednjega opomnika
                                </label>
                                <input
                                    required
                                    type="date"
                                    className="form-control form-control-sm"
                                    id="on_time_reminder_date"
                                    name="on_time_reminder_date"
                                    value={ this.state.form_on_time_reminder_date.replace('00:00:00', '').trim() }
                                    onChange={this.onChange}
                                    aria-describedby="on_time_reminder_data"
                                    placeholder="Datum"
                                />
                                <small
                                    id="on_time_reminder_data"
                                    className="form-text text-muted"
                                >
                                    Datum opomnika če je izbran "enkraten" tip
                                    uporabnika.
                                </small>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Uredi opomnik
                            </button>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default EditReminder;
