import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
class AddUser extends Component {
    state = {};
    static initialState = {
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
        role: ""
    };
    constructor(props) {
        super(props);

        this.state = AddUser.initialState;
        this.state.reset_after_add = false;

        this.form = React.createRef();
    }

    onFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            const { password, passwordConfirm } = this.state;

            if (password !== passwordConfirm) {
                toast.error("Gesla se ne ujemata");
                //alert("Gesla se ne ujemata");
            } else {
                const formData = new FormData();
                formData.append("name", this.state.name);
                formData.append("email", this.state.email);
                formData.append("password", this.state.password);
                formData.append("role", this.state.role);

                Axios.post(window.Settings.baseUrlApi + "/users", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.status == "error") {
                                if (response.data.error.includes("1062 Duplicate entry")) {
                                    toast.error(
                                        "Uporabnik s tem emailom že obstaja"
                                    );
                                }
                            } else if (response.data.status == "success") {
                                if (this.state.reset_after_add) {
                                    this.setState(AddUser.initialState);
                                    this.setState({ reset_after_add: true });
                                    toast.success("Uporabnik shranjen");
                                } else {
                                    toast.success("Uporabnik shranjen");
                                    this.props.history.push({
                                        pathname: "/users",
                                    });
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    onChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };

    onChangeRedirect = e => {
        this.setState({ reset_after_add: e.target.checked });
    };

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Dodaj novega uporabnika
                        </h1>
                        <form
                            className="col-md-4"
                            onSubmit={this.onFormSubmit}
                            ref={this.form}
                        >
                            {/*  Ime uporabnika */}
                            <div className="form-group">
                                <label htmlFor="name">Naziv</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    placeholder="Ime"
                                />
                            </div>
                            {/*  Email uporabnika */}
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    required
                                    type="email"
                                    className="form-control form-control-sm"
                                    id="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    aria-describedby="email_help"
                                    placeholder="Email"
                                />
                                <small
                                    id="email_help"
                                    className="form-text text-muted"
                                >
                                    Email naslov. Uporabnik se bo s tem naslovo
                                    prijavil v aplikacijo.
                                </small>
                            </div>
                            {/*  Tip uporabnika, pravice */}
                            <div className="form-group">
                                <label htmlFor="role">Pravice</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="role"
                                    name="role"
                                    value={this.state.role}
                                    onChange={this.onChange}
                                    aria-describedby="role_help"
                                >
                                    <option value="">
                                        Izberi tip uporabnika
                                    </option>
                                    <option value="50">Uporabnik</option>
                                    <option value="75">Administrator</option>
                                    <option value="90">
                                        Super administrator
                                    </option>
                                </select>
                                <small
                                    id="role_help"
                                    className="form-text text-muted"
                                >
                                    Pravice, ki jih bo imel uporabnik. Glede na
                                    pravice mu določeni deli aplikacije in
                                    dokumenti ne bodo na voljo
                                </small>
                            </div>
                            {/*  Geslo uporabnika */}
                            <div className="form-group">
                                <label htmlFor="password">Geslo</label>
                                <input
                                    required
                                    type="password"
                                    className="form-control form-control-sm"
                                    id="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    placeholder="Geslo"
                                />
                            </div>
                            {/*  Password Match */}
                            <div className="form-group">
                                <label htmlFor="passwordConfirm">
                                    Ponovi geslo
                                </label>
                                <input
                                    required
                                    type="password"
                                    className="form-control form-control-sm"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    value={this.state.passwordConfirm}
                                    onChange={this.onChange}
                                    placeholder="Ponovi geslo"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Dodaj uporabnika
                            </button>
                           {/*  in ostani na strani
                            <input
                                name="reset_after_add"
                                type="checkbox"
                                value="true"
                                onChange={this.onChangeRedirect}
                            /> */}
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default AddUser;
