import React, { Component } from "react";
import Axios from "axios";
import { /* Redirect, */ Link } from "react-router-dom";

const BuildTree = flatArray => {
    var tree = [],
        mappedArray = {},
        arrayElement,
        mappedElement;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = flatArray.length; i < len; i++) {
        arrayElement = flatArray[i];
        mappedArray[arrayElement.id] = arrayElement;
        mappedArray[arrayElement.id]["children"] = [];
    }

    for (var id in mappedArray) {
        if (mappedArray.hasOwnProperty(id)) {
            mappedElement = mappedArray[id];
            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElement.parent_id) {
                mappedArray[mappedElement["parent_id"]]["children"].push(
                    mappedElement
                );
            }
            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElement);
            }
        }
    }

    return tree;
};

const renderMenu = (items, action) => {
    if (!items) return <div />;

    return (
        <ul>
            {items.map(i => {
                return (
                    <li key={i.id}>
                        <Link
                            className="nav-link"
                            onClick={e => {
                                action(e, i.id);
                            }}
                            to={"/documents/document_category/" + i.id}
                        >
                            {i.name}
                        </Link>
                        {i.children && renderMenu(i.children, action)}
                    </li>
                );
            })}
        </ul>
    );
};

const Menu = ({ data, action }) => {
    if (!data) return <div />;

    return (
        <nav className="col-md-2">
            <ul>
                <li key="0">
                    <Link className="" onClick={ (e) => { action(e, 0) } } to="/documents">
                        Dokumenti
                    </Link>
                </li>
                {data.map(i => {
                    return (
                        <li key={i.id}>
                            <Link
                                className="nav-link"
                                onClick={e => {
                                    action(e, i.id);
                                }}
                                to={"/documents/document_category/" + i.id}
                            >
                                {i.name}
                            </Link>
                            {i.children && renderMenu(i.children, action)}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

class CategoryTreeView extends Component {
    state = {};

    constructor(props) {
        super(props);
  
        this.state = {
            categoriesJSON: null,
            categoriesTreeView: null
        };
    }

    componentDidMount() {
        Axios.get(window.Settings.baseUrlApi + "/document-categories", {}, {})
            .then(response => {
                let tree = BuildTree(response.data);

                this.setState({ categoriesJSON: response.data });
                this.setState({ categoriesTreeView: tree });
            })
            .catch(error => {
                console.log(error);
            });
    }
    // Render Method
    render() {
        return (
            <Menu
                data={this.state.categoriesTreeView}
                action={this.props.action}
            />
        );
    }
}

export default CategoryTreeView;
