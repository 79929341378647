import React, { Component } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

class EditUser extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            user: null,

            form_name: "",
            form_email: "",
            form_password: "",
            form_passwordConfirm: "",
            form_role: ""
        };

        this.form = React.createRef();
    }

    OnFormSubmit = e => {
        e.preventDefault(); // Stop form submit
        const { form_password, form_passwordConfirm } = this.state;

        if (form_password !== form_passwordConfirm) {
            toast.error("Gesla se ne ujemata");
            //alert("Gesla se ne ujemata");
        } else {
            const formData = new FormData();

            formData.append("_method", "put");
            formData.append("name", this.state.form_name);
            formData.append("email", this.state.form_email);
            formData.append("password", this.state.form_password);
            formData.append("role", this.state.form_role);

            Axios.post(
                window.Settings.baseUrlApi + "/users/" + this.state.user.id,
                formData,
                {
                    headers: {
                        "Content-Type": "x-www-form-urlencoded"
                    }
                }
            )
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.status == "error") {
                            // Error
                        } else if (response.data.status == "success") {
                            toast.success("Uporabnik shranjen");
                            this.props.history.push({
                                pathname: "/users",
                            });
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    onChange = e => {
        let stateObject = {};
        stateObject["form_" + e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };

    componentDidMount() {
        let idFromPathname = window.location.pathname.split("/")[2] || null;
        let user_id =
            (this.props.location.state && this.props.location.state.user_id) ||
            idFromPathname ||
            null;

        Axios.get(window.Settings.baseUrlApi + "/users/" + user_id, {}, {})
            .then(response => {
                this.setState({ user: response.data });

                this.setState({ form_name: response.data.name });
                this.setState({ form_email: response.data.email });
                this.setState({ form_role: response.data.role });
            })
            .catch(error => {
                console.log(error);
            });
    }
    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Uredi uporabnika
                        </h1>
                        <form
                            className="col-md-4"
                            onSubmit={e => this.OnFormSubmit(e)}
                            ref={this.form}
                        >
                            {/*  Ime uporabnika */}
                            <div className="form-group">
                                <label htmlFor="name">Naziv</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    name="name"
                                    value={this.state.form_name}
                                    onChange={this.onChange}
                                    placeholder="Ime"
                                />
                            </div>

                            {/*  Email uporabnika */}
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    required
                                    type="email"
                                    className="form-control form-control-sm"
                                    id="email"
                                    name="email"
                                    value={this.state.form_email}
                                    onChange={this.onChange}
                                    aria-describedby="email_help"
                                    placeholder="Email"
                                />
                                <small
                                    id="email_help"
                                    className="form-text text-muted"
                                >
                                    Email naslov. Uporabnik se bo s tem naslovo
                                    prijavil v aplikacijo.
                                </small>
                            </div>

                            {/*  Tip uporabnika, pravice */}
                            {/*  Tip uporabnika, pravice */}
                            <div className="form-group">
                                <label htmlFor="role">Pravice</label>
                                <select
                                    required
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="role"
                                    name="role"
                                    value={this.state.form_role}
                                    onChange={this.onChange}
                                    aria-describedby="role_help"
                                >
                                    <option value="50">Uporabnik</option>
                                    <option value="75">Administrator</option>
                                    <option value="90">
                                        Super administrator
                                    </option>
                                </select>
                                <small
                                    id="role_help"
                                    className="form-text text-muted"
                                >
                                    Pravice, ki jih bo imel uporabnik. Glede na
                                    pravice mu določeni deli aplikacije in
                                    dokumenti ne bodo na voljo
                                </small>
                            </div>

                            {/*  Geslo uporabnika */}
                            <div className="form-group">
                                <label htmlFor="password">Geslo</label>
                                <input
                                    type="password"
                                    className="form-control form-control-sm"
                                    id="password"
                                    name="password"
                                    onChange={this.onChange}
                                    placeholder="Geslo"
                                />
                            </div>
                            {/*  Password Match */}
                            <div className="form-group">
                                <label htmlFor="passwordConfirm">
                                    Ponovi geslo
                                </label>
                                <input
                                    type="password"
                                    className="form-control form-control-sm"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    onChange={this.onChange}
                                    placeholder="Ponovi geslo"
                                />
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Uredi uporabnika
                            </button>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default EditUser;
