import React, { Component } from "react";
import Axios from "axios";
import { /* Redirect, */ Link } from "react-router-dom";
import { toast } from "react-toastify";

const SortFields = props => {
    return (
        <React.Fragment>
            <button
                title="Naraščajoče"
                className="btn btn-outline-secondary btn-sm  ml-3"
                onClick={() => {
                    props.updateSort(props.order_by, "ASC");
                    props.getUsersForCurrentPathname();
                }}
            >
                <span className="oi oi-caret-top" />
            </button>

            <button
                title="Padajoče"
                className="btn btn-outline-secondary btn-sm ml-1"
                onClick={() => {
                    props.updateSort(props.order_by, "DESC");
                    props.getUsersForCurrentPathname();
                }}
            >
                <span className="oi oi-caret-bottom" />
            </button>
        </React.Fragment>
    );
};

const TableRow = props => {
    return (
        <tr>
            <td>{props.item.id}</td>
            <td> <Link
                            className="nav-link"
                           
                            to={"/user-details/" + props.item.id}
                        >
                            {props.item.name}
                        </Link></td>
            <td>{props.item.email}</td>
            <td>
                <button
                    title="Uredi uporabnika"
                    className="btn btn-outline-info btn-sm float-right"
                    onClick={() => {
                        props.editUser(props.item.id);
                    }}
                >
                    <span className="oi oi-cog" />
                </button>
                {/* <button
                    title="Izbriši uporabnika!"
                    className="btn btn-outline-danger btn-sm float-right mr-2"
                    onClick={() => {
                        props.deleteUser(props.item.id);
                    }}
                >
                    <span className="oi oi-trash" />
                </button> */}
            </td>
        </tr>
    );
};

class ShowAllUsers extends Component {
    state = {};

    // Construtor
    constructor(props) {
        super(props);

        this.state = {
            users: null
        };
    }

    updateSort = (order_by, dir) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?order_by=" + order_by + "&dir=" + dir
        });
    };

    getUsersForCurrentPathname = () => {
        Axios.get(
            window.Settings.baseUrlApi + 
                this.props.history.location.pathname +
                this.props.history.location.search,
            {},
            {}
        )
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

    deleteUser = id => {
        if (window.confirm("Ste prepričani da želite izbrisati uporabnika?")) {
            Axios.delete(window.Settings.baseUrlApi + "/users/" + id, {}, {})
                .then(response => {

                    this.getUsersForCurrentPathname();
                    toast.success("Uporabnik izbrisan");
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    editUser = id => {
        this.props.history.push({
            pathname: "edit-user/" + id,
            state: { user_id: id }
        });
    };

    componentDidMount() {
        this.getUsersForCurrentPathname();
    }

    render() {
        return (
            <React.Fragment>
                <table className="table col-md-12">
                    <thead>
                        <tr>
                            <th scope="col">
                                ID
                                <SortFields
                                    order_by="id"
                                    updateSort={this.updateSort}
                                    getUsersForCurrentPathname={
                                        this.getUsersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Ime
                                <SortFields
                                    order_by="name"
                                    updateSort={this.updateSort}
                                    getUsersForCurrentPathname={
                                        this.getUsersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Email
                                <SortFields
                                    order_by="email"
                                    updateSort={this.updateSort}
                                    getUsersForCurrentPathname={
                                        this.getUsersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {this.state &&
                            this.state.users &&
                            this.state.users.map((item, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        item={item}
                                        deleteUser={this.deleteUser}
                                        editUser={this.editUser}
                                    />
                                );
                            })}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default ShowAllUsers;
