import React, { Component } from "react";
import Axios from "axios";

class Search extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: "",
            searchResults: "",
            searchFilenameOnly: false
        };
    }

    componentDidMount() {
        this.searchInput.focus();
        let searchTerm = this.props.location.state.searchTerm;

        const formData = new FormData();
        formData.append("searchTerm", searchTerm);

        Axios.post(window.Settings.baseUrlApi + "/documents/search", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                this.setState({
                    searchResults: response.data.result
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    search() {
        const formData = new FormData();
        if (this.state.searchFilenameOnly === true) {
            formData.append("searchTerm", "external.filename:" + this.state.searchTerm);
            
        }
        else {
            formData.append("searchTerm", this.state.searchTerm);

        }

        Axios.post(window.Settings.baseUrlApi + "/documents/search", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                this.setState({
                    searchResults: response.data.result
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    // RENDER
    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="row">
                        <h1
                            className="h2 border-bottom col-md-12 display-4"
                            style={{
                                marginBottom: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            Rezultat iskanja
                        </h1>
                        <form
                            className="w-100"
                            onSubmit={event => {
                                event.preventDefault();
                                this.search();
                            }}
                        >
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Išči po dokumentih"
                                aria-label="Search"
                                defaultValue={
                                    this.props.location.state.searchTerm
                                }
                                ref={input => {
                                    this.searchInput = input;
                                }}
                                onChange={event => {
                                    this.setState({
                                        searchTerm: event.target.value
                                    });
                                }}
                            />
                            <input
                                className=""
                                onChange={event => {
                                    this.setState({
                                        searchFilenameOnly: !this.state.searchFilenameOnly
                                    });
                                }}
                                type="checkbox"
                                checked={this.state.searchFilenameOnly}
                                value="true"
                                name="searchFilenameOnly"
                            /> Išči samo po imenu datoteke 
                        </form>
                    </div>
                    <div className="row">
                        {this.state &&
                            this.state.searchResults &&
                            Object.entries(this.state.searchResults).map(
                                (item, i) => {
                                    return (
                                        <div
                                            className="search-results-result col-md-12"
                                            key={i}
                                        >
                                            <h3>
                                                <a
                                                    target="_blank"
                                                    href={
                                                        window.Settings.baseUrlApi + "/document_download/" + window.Auth.userId +"/" + item[1].id
                                                       
                                                    }
                                                >
                                                    {item[1].name}{" "}
                                                </a>
                                            </h3>
                                            <div style={{ padding: "5px" }}>
                                                Opis dokumenta: {item[1].description} <br />
                                                Datoteka: {item[1].filename}
                                                {item[1].hightlights &&
                                                    item[1].hightlights.map(
                                                        (highlight, hi) => {
                                                            return (
                                                                <div key={
                                                                    "hi-" +
                                                                    hi
                                                                }>
                                                                    <hr />

                                                                    <p
                                                                        
                                                                        className="highlights"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: highlight
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                </div>
            </main>
        );
    }
}
export default Search;
