import React, { Component } from "react";
import { /* Redirect, */ Link } from "react-router-dom";
import Axios from "axios";

const ulStyle = {
    paddingLeft: "20px"
};
const homeLinkStyle = {
    color: "#333",
    margin: "0",
    padding: "0",
    display: "inline-block",
    fontSize: ".75rem",
    textTransform: "uppercase"
};

const BuildTree = flatArray => {
    var tree = [],
        mappedArray = {},
        arrayElement,
        mappedElement;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = flatArray.length; i < len; i++) {
        arrayElement = flatArray[i];
        mappedArray[arrayElement.id] = arrayElement;
        mappedArray[arrayElement.id]["children"] = [];
    }
    
    for (var id in mappedArray) {
        if (mappedArray.hasOwnProperty(id)) {
            mappedElement = mappedArray[id];
            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElement.int1) {
                mappedArray[mappedElement["int1"]]["children"].push(
                    mappedElement
                );
            }
            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElement);
            }
        }
    }

    return tree;
};
const renderMenu = (items, action) => {
    if (!items) return <div />;

    return (
        <ul className="nav flex-column mb-2" style={ulStyle}>
            {items.map(i => {
                return (
                    <li key={i.id} className={(window.Auth.role >= i.minimum_role ? "" : " hidden ") + "nav-item"}>
                        <Link className="nav-link" to={"/" + i.str1}>
                            <span className={i.str2} />{" "}
                            {i.name}
                        </Link>
                        {i.children && renderMenu(i.children, action)}
                    </li>
                );
            })}
        </ul>
    );
};

const Menu = ({ data, action }) => {
    if (!data) return <div />;
    return (
        data.map(i => {
            return(
                <div key={i.id} className={(window.Auth.role >= i.minimum_role ? "" : " hidden ") + "nav-container"}  id="dokumenti">
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>
                            <span className={i.str2} />
                            {i.name}
                        </span>
                        <a
                            className="d-flex align-items-center text-muted"
                            href={i.str1}
                        >
                            <span data-feather="plus-circle" />
                        </a>
                    </h6>
                    
                        {i.children && renderMenu(i.children, action)}
                    
                </div>
            )
        })
    );
};

class NavLeft extends Component {
    state = {};
     // Construtor
     constructor(props) {
        super(props);

        this.state = {
            frontendSettingsRaw: null,
            frontendSettings: null
        };
    }

    componentDidMount() {
        let url = window.Settings.baseUrlApi + "/frontend-settings/main-nav";

      
        Axios.get(url, {}, {})
            .then(response => {
                this.setState({ frontendSettingsRaw: response.data });
                this.setState({ frontendSettings: BuildTree(response.data.frontendSettings) });
                
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <Menu
                    data={this.state.frontendSettings} action={function(){}}
                />
                
            </nav>
        );
    }
}

export default NavLeft;
