import React, { Component } from "react";
import Axios from "axios";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { /* Redirect, */ Link } from "react-router-dom";

const ReminderTypes = {
    1: "Enkraten",
    2: "Tedenski",
    3: "Mesečni",
    4: "Letni",
    5: "2 letni",
    6: "5 letni",
    7: "3 letni"
};

const Status = {
    0: "Zaprt",
    1: "Odprt",
    2: "V delu",
    3: "Potekel"
};
const StatusColors = {
    0: "rgba(255,255,255,0)",
    1: "rgba(255,255,255,0)",
    2: "rgba(255,255,255,0)",
    3: "rgba(255,0,0,0.3)"
};
const StatusBadge = {
    0: "badge badge-primary",
    1: "badge badge-success",
    2: "badge badge-warning",
    3: "badge badge-danger"
};

const SortFields = props => {
    return (
        <React.Fragment>
            <button
                title="Naraščajoče"
                className="btn btn-outline-secondary btn-sm  ml-3"
                onClick={() => {
                    props.updateSort(props.order_by, "ASC");
                    props.getRemindersForCurrentPathname();
                }}
            >
                <span className="oi oi-caret-top" />
            </button>
            <button
                title="Padajoče"
                className="btn btn-outline-secondary btn-sm  ml-1"
                onClick={() => {
                    props.updateSort(props.order_by, "DESC");
                    props.getRemindersForCurrentPathname();
                }}
            >
                <span className="oi oi-caret-bottom" />
            </button>
        </React.Fragment>
    );
};
const TableRow = props => {
    return (
        <tr style={{ background: StatusColors[props.item.status] }}>
            <td>
                <Link
                    className="nav-link"
                    to={"/reminder-details/" + props.item.id}
                >
                    {props.item.name} 
                </Link>
                {props.item.finished_note && <span title={props.item.finished_note} style={{marginLeft: "10px"}} className="oi oi-chat"></span>}
            </td>
            <td>{ReminderTypes[props.item.reminder_type]}</td>
            <td>{props.item.description}</td>
            <td>{props.item.user.email}</td>
            <td>
                <span style={{ fontSize: '85%' }} className={StatusBadge[props.item.status]}>
                    {Status[props.item.status]}
                </span>
            </td>
            <td>{window.FormateDateToSLO(props.item.on_time_reminder_date)}</td>
            <td>
                <button
                    title="Uredi opomnik"
                    className={(window.Auth.role >= 90 ? "" : " hidden ") + "btn btn-outline-info btn-sm float-right"}
                    onClick={() => {
                        props.editReminder(props.item.id);
                    }}
                >
                    <span className="oi oi-cog" />
                </button>
                <button
                    title="Izbriši opomnik!"
                    className={(window.Auth.role >= 90 ? "" : " hidden ") + "btn btn-outline-danger btn-sm float-right mr-2"}
                    onClick={() => {
                        props.deleteReminder(props.item.id);
                    }}
                >
                    <span className="oi oi-trash" />
                </button>
            </td>
        </tr>
    );
};

class showAllReminders extends Component {
    state = {};

    // Construtor
    constructor(props) {
        super(props);

        this.state = {
            reminders: null,
            isLoading: true
        };
    }

    getRemindersForCurrentPathname = () => {
        Axios.get(
            window.Settings.baseUrlApi +
                this.props.history.location.pathname +
                this.props.history.location.search,
            {},
            {}
        )
            .then(response => {
                this.setState({ reminders: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateSort = (order_by, dir) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?order_by=" + order_by + "&dir=" + dir
        });
    };

    deleteReminder = id => {
        if (window.confirm("Ste prepričani da želite izbrisati opomnik?")) {
            Axios.delete(
                window.Settings.baseUrlApi + "/reminders/" + id,
                {},
                {}
            )
                .then(response => {
                    this.getRemindersForCurrentPathname();
                    toast.success("Opomnik je izbrisan");
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    editReminder = id => {
        this.props.history.push({
            pathname: "edit-reminder/" + id,
            state: { reminder_id: id }
        });
    };

    componentDidMount() {
        let url = window.Settings.baseUrlApi + "/reminders";

        Axios.get(url, {}, {})
            .then(response => {
                this.setState({ reminders: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <table className="table col-md-12">
                    <thead>
                        <tr>
                            <th scope="col">
                                Naziv
                                <SortFields
                                    order_by="name"
                                    updateSort={this.updateSort}
                                    getRemindersForCurrentPathname={
                                        this.getRemindersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Tip opomnika
                                <SortFields
                                    order_by="reminder_type"
                                    updateSort={this.updateSort}
                                    getRemindersForCurrentPathname={
                                        this.getRemindersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Opis
                                <SortFields
                                    order_by="description"
                                    updateSort={this.updateSort}
                                    getRemindersForCurrentPathname={
                                        this.getRemindersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Uporabnik
                                <SortFields
                                    order_by="user_id"
                                    updateSort={this.updateSort}
                                    getRemindersForCurrentPathname={
                                        this.getRemindersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Status
                                <SortFields
                                    order_by="status"
                                    updateSort={this.updateSort}
                                    getRemindersForCurrentPathname={
                                        this.getRemindersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col">
                                Datum
                                <SortFields
                                    order_by="on_time_reminder_date"
                                    updateSort={this.updateSort}
                                    getRemindersForCurrentPathname={
                                        this.getRemindersForCurrentPathname
                                    }
                                />
                            </th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {this.state &&
                            this.state.reminders &&
                            this.state.reminders.map((item, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        item={item}
                                        deleteReminder={this.deleteReminder}
                                        editReminder={this.editReminder}
                                    />
                                );
                            })}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default showAllReminders;
