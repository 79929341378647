import React, { Component } from "react";
import Axios from "axios";
import Cookies from "universal-cookie";

class Login extends Component {
    state = {};
    static initialState = {
        client_id: "4",
        client_secret: "AcG8czWTTIL86GBddYpswOTlqguaIbz0alNwoFQs",
        grant_type: "password",
        username: "",
        password: "",
        scope: "",

        user: ""
    };

    constructor(props) {
        super(props);

        this.state = Login.initialState;
        this.form = React.createRef();
    }

    onFormSubmit = e => {
        e.preventDefault(); // Stop form submit

        if (this.form.current.reportValidity()) {
            const formData = new FormData();
            formData.append("client_id", this.state.client_id);
            formData.append("client_secret", this.state.client_secret);
            formData.append("grant_type", this.state.grant_type);
            formData.append("username", this.state.username);
            formData.append("password", this.state.password);
            formData.append("scope", this.state.scope);

            // Probaj login window.Settings.baseUrlApi
            Axios.post(window.Settings.baseUrl + "/oauth/token", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.data) {
                        // Nastavi login status v global auth objekt
                        window.Auth.isLoggedIn = true;
                        window.Auth.token = response.data.access_token;
                        // Nastavi bearer token za vse axios requeste
                        Axios.interceptors.request.use(
                            config => {
                                let token = window.Auth.token;

                                if (token) {
                                    config.headers[
                                        "Authorization"
                                    ] = `Bearer ${token}`;
                                }

                                return config;
                            },

                            error => {
                                return Promise.reject(error);
                            }
                        );
                        // get podatke uporabnika
                        return Axios.get(
                            window.Settings.baseUrlApi + "/users/me"
                        );
                    }
                })
                .then(response => {
                    if (response) {
                        // Zapiši ostale uporabniške podatke v globalen auth objekt
                        window.Auth.userId = response.data.user.id;
                        window.Auth.username = response.data.user.email;
                        window.Auth.role = response.data.user.role;
                        // Zapiši cookie
                        const cookies = new Cookies();

                        cookies.set("isLoggedIn", window.Auth.isLoggedIn, {
                            path: "/",
                            maxAge: 43200
                        });
                        cookies.set("token", window.Auth.token, {
                            path: "/",
                            maxAge: 43200
                        });
                        cookies.set("userId", window.Auth.userId, {
                            path: "/",
                            maxAge: 43200
                        });
                        cookies.set("username", window.Auth.username, {
                            path: "/",
                            maxAge: 43200
                        });
                        cookies.set("role", window.Auth.role, {
                            path: "/",
                            maxAge: 43200
                        });
                            
                        // Redirect
                        this.props.history.push({
                            pathname: window.Auth.role >= 90 ? "/" : "/reminders"
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    onChange = e => {
        let stateObject = {};
        stateObject[e.currentTarget.name] = e.target.value;
        this.setState(stateObject);
    };

    render() {
        return (
            <div
                style={{ margin: "0 auto" }}
                className="col-md-4 justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3"
            >
                <form onSubmit={this.onFormSubmit} ref={this.form}>
                    {window.authMessage && window.authMessage}
                    <div className="form-group">
                        <label htmlFor="username">Email naslov</label>
                        <input
                            required
                            type="email"
                            name="username"
                            className="form-control"
                            id="username"
                            placeholder="Vpiši email"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username">Geslo</label>
                        <input
                            required
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            placeholder="Vpiši geslo"
                            onChange={this.onChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary col-md-12">
                        Prijava
                    </button>
                </form>
            </div>
        );
    }
}

export default Login;
